<template>
  <div>
    <!-- <v-row>
      <v-col>
    <pre>{{ parte }}</pre>

      </v-col>
      <v-col>
        <pre>{{ parteData }}</pre>
      </v-col>
    </v-row> -->
    <div class="card">
      <div class="card-header card-header-icon">
        <div class="card-icon bg-deep-blue">
          {{ `Folio: ${parte.folio}  FAENA DE ${tipo_parte} (${texto_carga})` }}
        </div>
      </div>
      <div class="card-body">
        <v-row class="mx-0">
          <v-col cols="6" class="shadow">
            <label for="" class="titulo-label">INFORMACIÓN GENERAL</label>
            <v-select
              v-model="parte.sentidos_id"
              :items="sentidos"
              item-text="nombre"
              item-value="id"
              label="Zarpe hacia Muelle / hacia C. Cultivo"
              @change="cambiaSentido"
            >
            </v-select>
            <v-select
              :disabled="es_descarga"
              v-model="parte.empresas_id"
              :items="empresas"
              item-text="razon_social"
              item-value="id"
              label="Empresa"
              @change="cargarCentrosEmpresa"
            >
            </v-select>
            <v-select
              v-model="parte.tipo_origenes_id"
              :items="tipo_origenes"
              item-text="nombre"
              item-value="id"
              label="Punto de origen"
              disabled
            >
            </v-select>
            <v-autocomplete
              v-model="parte.origen_id"
              :items="origenes"
              color="white"
              hide-no-data
              hide-selected
              item-text="nombre"
              item-value="id"
              label="Origen"
              placeholder="Seleccione Origen"
              prepend-icon="mdi-database-search"
              @change="buscarMillas"
              disabled
            ></v-autocomplete>
            <v-autocomplete
              v-model="parte.destino_id"
              :items="destinos"
              color="white"
              hide-no-data
              hide-selected
              item-text="nombre"
              item-value="id"
              label="Destino"
              placeholder="Seleccione Destino"
              prepend-icon="mdi-database-search"
              @change="buscarMillas"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" class="shadow">
            <label for="" class="titulo-label">MOVIMIENTOS NAVE</label>
            <v-datetime-picker
              label="Zarpe"
              v-model="parte.zarpe"
              dateFormat="dd-MM-yyyy"
              :datePickerProps="{
                locale: 'es-es',
                prevIcon: 'chevron_left',
                nextIcon: 'chevron_right',
              }"
              :timePickerProps="{ format: '24hr' }"
              @input="calculos"
            >
              <template slot="dateIcon">
                <v-icon>calendar_today</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>access_time</v-icon>
              </template>
            </v-datetime-picker>
            <v-datetime-picker
              label="Recalada"
              v-model="parte.recalada"
              dateFormat="dd-MM-yyyy"
              :datePickerProps="{
                locale: 'es-es',
                prevIcon: 'chevron_left',
                nextIcon: 'chevron_right',
              }"
              :timePickerProps="{ format: '24hr' }"
              @input="calculos"
            >
              <template slot="dateIcon">
                <v-icon>calendar_today</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>access_time</v-icon>
              </template>
            </v-datetime-picker>
            <v-datetime-picker
              label="Atraco/Fondeo"
              v-model="parte.atraco_fondeo"
              dateFormat="dd-MM-yyyy"
              :datePickerProps="{
                locale: 'es-es',
                prevIcon: 'chevron_left',
                nextIcon: 'chevron_right',
              }"
              :timePickerProps="{ format: '24hr' }"
            >
              <template slot="dateIcon">
                <v-icon>calendar_today</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>access_time</v-icon>
              </template>
            </v-datetime-picker>

            <v-datetime-picker
              label="Inicio (Fecha Hora)"
              v-model="parte.inicio_faena"
              dateFormat="dd-MM-yyyy"
              :datePickerProps="{
                locale: 'es-es',
                prevIcon: 'chevron_left',
                nextIcon: 'chevron_right',
              }"
              :timePickerProps="{ format: '24hr' }"
              disabled
            >
              <template slot="dateIcon">
                <v-icon>calendar_today</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>access_time</v-icon>
              </template>
            </v-datetime-picker>

            <v-row>
              <v-col>
                <v-datetime-picker
                  label="Termino (Fecha Hora)"
                  v-model="parte.termino_faena"
                  dateFormat="dd-MM-yyyy"
                  :datePickerProps="{
                    locale: 'es-es',
                    prevIcon: 'chevron_left',
                    nextIcon: 'chevron_right',
                  }"
                  :timePickerProps="{ format: '24hr' }"
                  disabled
                >
                  <template slot="dateIcon">
                    <v-icon>calendar_today</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>access_time</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>

              <v-col>
                <v-datetime-picker
                  label="Termino (Fecha Hora) CAM"
                  v-model="parte.termino_cam"
                  dateFormat="dd-MM-yyyy"
                  :datePickerProps="{
                    locale: 'es-es',
                    prevIcon: 'chevron_left',
                    nextIcon: 'chevron_right',
                  }"
                  :timePickerProps="{ format: '24hr' }"
                  disabled
                >
                  <template slot="dateIcon">
                    <v-icon>calendar_today</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>access_time</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1"> </v-col>
        </v-row>
        <v-row v-if="alert.mostrar">
          <v-col>
            <v-alert border="left" outlined text :type="alert.tipo">
              <v-row>
                <v-col>
                  <h4>{{ alert.mensaje }}</h4>
                </v-col>
                <v-col class="text-right">
                  <v-btn
                    :color="alert.color_boton"
                    elevation="2"
                    small
                    dark
                    @click="cerrar_alert"
                    >Cerrar</v-btn
                  >
                </v-col>
              </v-row>
              <v-row v-if="alert.errores.length > 0">
                <v-col>
                  <ul>
                    <li v-for="e in alert.errores" :key="e">{{ e }}</li>
                  </ul>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-row>
        <v-row class="mx-4">
          <div class="card mb-1 mt-4">
            <div class="card-header card-header-icon">
              <div class="card-icon p-0 m-0 bg-deep-blue">
                <h6 class="card-title text-white m-2">Cuadro Resumen</h6>
              </div>
            </div>
            <div class="card-body">
              <v-row class="mx-0">
                <v-col cols="1"> </v-col>
                <v-col cols="2">
                  <v-text-field
                    prepend-icon="mdi-timelapse"
                    placeholder="Tiempo Faena (hh:mm)"
                    label="Tiempo Faena"
                    readonly
                    v-model="parte.tiempo_faena"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    prepend-icon="mdi-timelapse"
                    placeholder="Distancia (MN)"
                    label="Distancia (MN)"
                    v-model="parte.millas"
                    type="number"
                    min="0"
                    @change="calcularNudos"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    prepend-icon="mdi-timelapse"
                    placeholder="Horas Navegadas (hh:mm)"
                    label="Horas"
                    v-model="parte.horas"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    prepend-icon="mdi-speedometer"
                    placeholder="Velocidad (Nudos)"
                    label="Nudos"
                    v-model="parte.nudos"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-row>
        <v-row class="mx-1">
          <v-col cols="12">
            <v-textarea
              counter
              maxlength="255"
              outlined
              label="Observaciones"
              clearable
              rows="2"
              no-resize
              v-model="parte.observaciones"
              clear-icon="mdi-close-circle"
            >
            </v-textarea>
          </v-col>
          <v-col cols="12" v-if="parte.numero == 1">
            <input type="checkbox" v-model="duplicar" /> Doble Carga
          </v-col>
          <v-col cols="12" v-if="parte.numero == 1">
            <input type="checkbox" v-model="doble_descarga" /> Doble Descarga
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-btn
              :disabled="!btnTemporal"
              color="primary"
              @click="guardarParte(1)"
              dense
              >Guardar Temporal</v-btn
            >
          </v-col>
          <v-col cols="4">
            <v-btn
              color="primary"
              @click="guardarParte(0)"
              dense
              :disabled="finalDisabled"
              >Guardar Parte de Viaje</v-btn
            >
          </v-col>
          <v-col cols="4">
            <v-btn color="default" @click="cancelar" dense>Cancelar</v-btn>
          </v-col>
        </v-row>
        <!-- notificacion -->
        <!-- <v-snackbar
        v-model="snackbar"
        :timeout="5000"
        :color="color"
      >
        {{ validation_message }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Cerrar
          </v-btn>
        </template>
      </v-snackbar> -->
        <!-- bloqueo de pantalla -->
        <!-- <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    parte: {
      id: 0,
      folio: null,
      es_descarga: false,
      empresas_id: null,
      smolt: 1,
      tipo_destinos_id: null,
      zarpe: "",
      recalada: "",
      horas: "",
      millas: "",
      tipopartes_id: 3, //smolt
      numero: 1,
    },
    es_descarga: false,
    wellboat: null,
    empresas: [],
    especies: [],
    //acopios:[],
    muelles: [],
    tipo_destinos: [],
    centros: [],
    sentidos: [],
    tipo_origenes: [
      { id: 1, nombre: "Centro de Cultivo" },
      { id: 2, nombre: "Centro de Acopio" },
      { id: 3, nombre: "Muelle/Bahia" },
      { id: 4, nombre: "Planta de Proceso" },
    ],
    origenes: [],
    destinos: [],
    btnTemporal: true,
    finalDisabled: true,
    temporal: 1,
    duplicar: false,
    doble_descarga: false,
    ultimo_parte: null,
    alert: {
      mostrar: false,
      tipo: "",
      color_boton: "",
      mensaje: "",
      errores: [],
    },
    tipo_parte: "",
  }),
  async mounted() {
    this.empresas = this.$store.getters["empresa/getActive"];
    this.especies = this.$store.getters["especie/getActive"];
    //this.acopios  = this.$store.getters['acopio/getActive'];
    this.muelles = this.$store.getters["muelle/getActive"];
    this.tipo_destinos = this.$store.getters["tipo_destino/getAll"];
    //this.sentidos = [{id: 2, nombre: 'A Centro Cultivo'}, {id: 3, nombre: 'A Muelle/Bahía'}],

    //revisar si el parte viene del store (es un parte ya creado)
    if (this.$store.state.parte.id != undefined) {
      //es un parte existente
      await this.cargarParte(this.$store.state.parte.id);
      //this.parte = this.$store.state.parte
      //this.wellboat = this.parte.wellboat
      this.finalDisabled = false;
    } else {
      //console.log("nuevo parte de smolt");
      //es un parte nuevo, obtener ultimo numero de folio
      this.wellboat = this.$store.state.user.wellboat;
      this.obtenerUltimoFolio();
    }
  },
  methods: {
    async obtenerUltimoFolio() {
      //obtiene el ultimo numero de folio, además del punto de origen del nuevo parte
      let url = `${this.base_url}partes/${
        this.wellboat.id
      }/ultimo_folio/tipo_partes/${3}`; //3 = smolt
      await this.axios
        .get(url, this.headers_json)
        .then(async(r) => {
          console.log("R", r.data.origenes);
          //inicializando parte de smolt nuevo
          this.$set(this.parte, "wellboats_id", this.wellboat.id);
          this.$set(this.parte, "folio", r.data.folio);
          this.$set(this.parte, "sentidos_id", r.data.sentidos_id);
          this.$set(this.parte, "tipo_origenes_id", r.data.tipo_origenes_id);
          this.$set(this.parte, "origen_id", r.data.origen_id);
          this.$set(this.parte, "es_descarga", r.data.es_descarga);
          this.$set(this.parte, "numero", r.data.numero);
          this.$set(this.parte, "empresas_id", r.data.empresas_id);

          this.origenes = r.data.origenes;
          this.sentidos = r.data.sentidos;
          this.ultimo_parte = r.data.ultimo_parte;
          this.tipo_parte = r.data.tipo_parte;
          this.es_descarga = this.parte.es_descarga == 1? true:false;
          if(this.es_descarga){
            this.$set(this.parte, "destino_id", r.data.destino_id);
          }
          await this.cargarCentrosEmpresa();
          this.cambiaSentido();
        })
        .catch((e) => {
          console.log("error", e);
        });
    },

    async cargarParte(id) {
      let url = `${this.base_url}partes/${id}/cargar`;
      await this.axios
        .get(url, this.headers_json)
        .then(async (r) => {
          r.data.data.zarpe = this.string_a_fecha(r.data.data.zarpe);
          r.data.data.recalada = this.string_a_fecha(r.data.data.recalada);
          r.data.data.atraco_fondeo = this.string_a_fecha(
            r.data.data.atraco_fondeo
          );
          r.data.data.inicio_faena = this.string_a_fecha(
            r.data.data.inicio_faena
          );
          r.data.data.termino_faena = this.string_a_fecha(
            r.data.data.termino_faena
          );
          r.data.data.termino_cam = this.string_a_fecha(
            r.data.data.termino_cam
          );
          this.parte = r.data.data;
          this.calcularNudos();
          this.origenes = r.data.origenes;
          this.sentidos = r.data.sentidos;
          this.ultimo_parte = r.data.ultimo_parte;
          this.tipo_parte = r.data.tipo_parte;
          this.duplicar = r.data.data.duplicar == 1 ? true : false;
          this.doble_descarga = r.data.data.doble_descarga == 1 ? true : false;
          this.es_descarga = this.parte.es_descarga == 1?true:false;
          await this.cargarCentrosEmpresa();
          this.cambiaSentido();
        })
        .catch((e) => {
          console.log("Error carga Parte", e.response);
        });
    },

    async cargarCentrosEmpresa() {
      let url = `${this.base_url}empresas/${this.parte.empresas_id}/centros`;
      await this.axios
        .get(url, this.headers_json)
        .then((r) => {
          //console.log("centros de cultivo", r.data.data);
          this.centros = r.data.data;
          this.loadDestinos();
          //this.loadOrigenes()
        })
        .catch((e) => {
          console.log("error al cargar centros", e);
        });
    },

    string_a_fecha(fecha) {
      if (!fecha) return null;
      return new Date(fecha);
    },

    loadOrigenDestino() {
      this.cambiaSentido();
    },

    cambiaSentido() {
      if (this.parte.sentidos_id == 2) {
        //centros de cultivo
        this.parte.tipo_destinos_id = this.tipo_destinos[0].id;
      }
      if (this.parte.sentidos_id == 3) {
        //muelles
        this.parte.tipo_destinos_id = this.tipo_destinos[2].id;
      }
      this.loadDestinos();
    },

    loadDestinos() {
      if (this.parte.tipo_destinos_id == 1) {
        this.destinos = this.centros;
      }
      if (this.parte.tipo_destinos_id == 2) {
        this.destinos = this.acopios;
      }
      if (this.parte.tipo_destinos_id == 3) {
        this.destinos = this.muelles;
      }
    },

    loadOrigenes() {
      if (this.parte.tipo_origenes_id == 1) {
        this.origenes = this.centros;
      }
      if (this.parte.tipo_origenes_id == 2) {
        this.origenes = this.acopios;
      }
      if (this.parte.tipo_origenes_id == 3) {
        this.origenes = this.muelles;
      }
    },

    async buscarMillas() {
      if (this.parte.origen && this.parte.destino) {
        let url = `${this.base_url}partes/millas/${this.parte.destino.id}/${this.parte.origen.id}`;
        if (this.parte.sentido.id == 2) {
          url = `${this.base_url}partes/millas/${this.parte.origen.id}/${this.parte.destino.id}`;
        }
        await this.axios
          .get(url, this.headers_json)
          .then((response) => {
            this.parte.millas = response.data.millas;
          })
          .catch((error) => {
            console.log(error);
          });
      }
      //this.comprobarOrigenDestino()
    },

    cancelar() {
      this.cleanForm();
      this.$store.commit("setParte", {});
      this.$router.push("/partes");
    },

    cleanForm() {
      this.finalDisabled = true;
      this.parte = {};
      //this.parte.especie = this.especies[0];
      this.parte.zarpe = "";
      this.parte.recalada = "";
      this.parte.atraco_fondeo = "";
      this.parte.inicio_faena = "";
      this.parte.termino_faena = "";
    },

    calcularTiempoFaena: function () {
      let inicio = this.moment(this.parte.inicio_faena);
      let termino = this.moment(this.parte.termino_cam);
      let seconds = termino.diff(inicio, "seconds");
      let millis = this.moment.duration({ seconds: seconds }).asMilliseconds();
      if (isNaN(millis)) {
        return "00:00";
      }
      return this.moment.utc(millis).format("HH:mm");
    },

    async guardarParte(temporal) {
      this.cerrar_alert();
      this.temporal = temporal;
      let url = `${this.base_url}partes/guardar`;
      //console.log('body',this.headers_json)
      await this.axios
        .post(url, this.parteData(), this.headers_json)
        .then((r) => {
          //this.$set(this.parte,"id",r.data.data.id)
          this.alert.mostrar = true;
          this.alert.tipo = "success";
          this.alert.color_boton = "green";
          this.alert.mensaje = r.data.message;
          this.$router.push("/partes");
        })
        .catch((e) => {
          this.alert.mostrar = true;
          this.alert.tipo = "error";
          this.alert.color_boton = "red";
          this.alert.mensaje = e.response.data.message;
          this.alert.errores = e.response.data.errors;
        });
    },

    parteData() {
      return {
        id: this.parte.id,
        folio: this.parte.folio,
        empresas_id: this.parte.empresas_id,
        wellboats_id: this.parte.wellboats_id,
        tipopartes_id: this.parte.tipopartes_id,
        sentidos_id: this.parte.sentidos_id,
        tipo_origenes_id: this.parte.tipo_origenes_id,
        origen_id: this.parte.origen_id,
        tipo_destinos_id: this.parte.tipo_destinos_id,
        destino_id: this.parte.destino_id,
        zarpe: this.$options.filters.format_fecha_hora(this.parte.zarpe),
        recalada: this.$options.filters.format_fecha_hora(this.parte.recalada),
        atraco_fondeo: this.$options.filters.format_fecha_hora(
          this.parte.atraco_fondeo
        ),
        inicio_faena: this.$options.filters.format_fecha_hora(
          this.parte.inicio_faena
        ),
        termino_faena: this.$options.filters.format_fecha_hora(
          this.parte.termino_faena
        ),
        termino_cam: this.$options.filters.format_fecha_hora(
          this.parte.termino_cam
        ),
        tiempo_faena: this.calcularTiempoFaena(),
        millas: this.parte.millas,
        horas: this.parte.horas,
        nudos: this.parte.nudos,
        observaciones: this.parte.observaciones,
        especies_id:
          this.parte.especies_id == undefined ? 3 : this.parte.especies_id,
        temporal: this.temporal,
        tipo_registros_id: 0,
        smolt: 1,
        centros_id: this.parte.centro != undefined ? this.parte.centro.id : 0,
        duplicar: this.duplicar == true ? 1 : 0,
        doble_descarga: this.doble_descarga == true ? 1 : 0,
        es_descarga: this.parte.es_descarga,
        ultimo_parte: this.ultimo_parte,
      };
    },

    cerrar_alert() {
      this.alert.mostrar = false;
      this.alert.tipo = "";
      this.alert.color_boton = "";
      this.alert.mensaje = "";
      this.alert.errores = [];
    },

    calculos() {
      this.calcularHoras();
      this.calcularNudos();
    },

    calcularHoras() {
      let inicio = this.moment(this.parte.zarpe);
      let termino = this.moment(this.parte.recalada);
      let seconds = termino.diff(inicio, "seconds");
      let millis = this.moment.duration({ seconds: seconds }).asMilliseconds();
      if (isNaN(millis)) {
        return "00:00";
      }
      let tempTime = this.moment.duration(millis);
      let horas_min =
        tempTime.days() * 24 + tempTime.hours() + ":" + tempTime.minutes();
      this.$set(this.parte, "horas", horas_min);
    },

    calcularNudos() {
      if (this.parte.millas < 0) this.parte.millas = 0;
      let inicio = this.moment(this.parte.zarpe);
      let termino = this.moment(this.parte.recalada);
      let horas = termino.diff(inicio, "minutes") / 60;
      let millas = this.parte.millas;
      let nudos = millas / horas;
      nudos = parseFloat(nudos.toFixed(1));
      console.log("millas", millas, "horas", horas);
      if (isNaN(nudos)) {
        return "0";
      }
      this.$set(this.parte, "nudos", nudos);
    },
  },
  computed: {
    texto_carga() {
      let texto = "";
      if (this.parte.es_descarga == 0) {
        texto = "CARGA";
      }
      if (this.parte.es_descarga == 1) {
        texto = "DESCARGA";
      }
      return texto;
    },

    ...mapState(["base_url", "headers_json", "authenticated"]),
  },
};
</script>
